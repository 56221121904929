@import 'src/utils/utils';

.links {
  @include zero;
  margin-top: 20px;
  background-color: $Main-2;
  padding: 16px;
  background-color: $Main-2;
  margin-bottom: 20px;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
    padding: 20px;
  }

  &__header {
    @include zero;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $Main-5;

    svg {
      min-width: 24px;
      width: 24px;
      height: 24px;
    }

    span {
      @include Text-16-bold;
      color: $Main-1;

      @media(min-width: $xxl) {
        @include Text-18-bold;
      }
    }
  }

  &__link {
    @include Text-14-reg;
    color: $Main-1;
    transition: 0.3s;
    text-decoration: none;
    display: block;
    margin-top: 5px;

    @media(min-width: $lg) {
      @include Text-16-reg;
      display: flex;
      column-gap: 10px;
    }
    @media(min-width: 1620px) {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    span {
      color: $t-40;
    }
  }
}